<script>
import {useLiveStreamStore} from "@/stores/livestream";
import helpers from "../../helpers/helpers";

export default {
  name: "RecentLiveStreamsList",
  computed: {
    helpers() {
      return helpers;
    }
  },
  methods: {
    useLiveStreamStore,
    getFormattedDate(isoString) {
      const date = new Date(isoString);
      const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      };
      return date.toLocaleDateString('en-GB', options);
    },
    getFormattedDateTime(isoString) {
      const date = new Date(isoString);
      return this.getFormattedDate(isoString) + ', ' + date.toLocaleTimeString('de-DE', {timeZone: 'UTC'});
    },
    getFormattedDuration(duration) {
      return (Math.round(duration / 60 * 100) / 100) + ' min';
    },
    playVideo(flight, key, index) {
      useLiveStreamStore().activeVideo = {
        // eslint-disable-next-line no-undef
        videoTitle: this.getFormattedDateTime(flight.date),
        videoDescription: flight.description,
        videoDetails: flight.details,
        videoDuration: flight.duration,
        videoUrl: flight.url,
        videoLocation: flight.location,
      };
      useLiveStreamStore().videoDate = key;
      useLiveStreamStore().videoIndex = index;
      useLiveStreamStore().isLivestreamVideoActive = true;
    },
  },
  data() {
    return {};
  }
};
</script>

<template>
  <v-list expand>
    <div class="text-h6" style="color: rgba(0, 0, 0, 0.6)">Recent Livestreams</div>
    <div v-for="(flights, date, index) in useLiveStreamStore().recentFlights" :key="date">
      <v-list-group :value="index === 0">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1">{{ getFormattedDate(date) }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <div v-for="(flight, index) in flights" :key="index">
          <v-list-item class="ml-5 , my-2">
            <div class="d-flex flex-row align-center" style="width: 100%">
              <div class="d-flex flex-column">
                <div class="d-flex flex-row align-center">
                  <v-icon
                      :style="useLiveStreamStore().isActiveVideo(date, index) ? 'color: #1976d2' : ''">
                    mdi-clock
                  </v-icon>
                  <div class="ml-2 text-subtitle-2"
                       :style="useLiveStreamStore().isActiveVideo(date, index) ? 'color: #1976d2' : ''"
                       style="color: rgba(0, 0, 0, 0.6)">{{ flight.date.slice(11, 19) }}
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <v-icon
                      :style="useLiveStreamStore().isActiveVideo(date, index) ? 'color: #1976d2' : ''">
                    mdi-clock-digital
                  </v-icon>
                  <div class="ml-2 text-subtitle-2 my-1"
                       :style="useLiveStreamStore().isActiveVideo(date, index) ? 'color: #1976d2' : ''"
                       style="color: rgba(0, 0, 0, 0.6)">{{ getFormattedDuration(flight.duration) }}
                  </div>
                </div>
                <div class="text-subtitle-2" style="color: rgba(0, 0, 0, 0.6)">Description:
                  {{ flight.description }}
                </div>
              </div>
              <v-btn icon x-large class="ml-auto mr-0" @click="playVideo(flight, date, index)">
                <v-icon :style="useLiveStreamStore().isActiveVideo(date, index) ? 'color: #1976d2' : ''">
                  mdi-play
                </v-icon>
              </v-btn>
            </div>
            <v-divider
                :class="useLiveStreamStore().isActiveVideo(date, index) ? 'primary' : ''"></v-divider>
          </v-list-item>
          <v-divider
              :class="useLiveStreamStore().isActiveVideo(date, index) ? 'primary' : ''"></v-divider>
        </div>
      </v-list-group>
    </div>
  </v-list>
</template>

<style scoped>

</style>