<script>
import MapService from "@/views/map/MapService.vue";

export default {
  name: "MapIndex",
  components: {MapService},
};
</script>

<template>
  <MapService/>
</template>

<style scoped>

</style>