<template>
  <div class="p-0 m-0">
    <div id="map" class="map"></div>
    <!--Baselayers Button-->
    <div style="position:absolute; right: 20px; top: 20px; z-index: 90;">
      <v-btn v-if="!baseLayerMenuActive" :disabled="layersMenuActive" @click="baseLayerMenuActive = true"
             color="primary" dark rounded fab small>
        <v-icon>mdi-layers-triple</v-icon>
      </v-btn>
    </div>
    <!--Layer Button-->
    <div style="position:absolute; right: 20px; top: 75px; z-index: 90;">
      <v-btn v-if="!layersMenuActive" @click="layersMenuActive = true" color="primary" dark rounded fab small>
        <v-icon>mdi-map-outline</v-icon>
      </v-btn>
    </div>
    <!--BaseLayers/Layers Menu-->
    <div v-if="layersMenuActive || baseLayerMenuActive"
         :style="layersMenuActive ? 'top: 75px;' : 'top: 20px;'"
         style="position:absolute; right: 20px; z-index: 100;">
      <v-card
          outlined
          width="400"
          class="rounded-lg"
      >
        <v-list v-if="baseLayerMenuActive" dense>
          <v-list-item-group v-model="selectedBaseLayer" color="primary" mandatory>
            <div class="flex flex-row pt-0">
              <v-subheader class="text-h6"><span>Base Maps</span>
                <v-spacer></v-spacer>
                <v-btn @click="baseLayerMenuActive = false" icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-subheader>
            </div>
            <v-list-item v-for="layer in baseLayers" :key="layer.name">
              <v-list-item-icon>
                <img class="rounded-xl" :src="layer.img" :alt="layer.name"/>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="layer.heading"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-list dense v-else>
          <v-list-item-group v-model="selectedLayers" color="primary" multiple>
            <div class="flex flex-row pt-0">
              <v-subheader class="text-h6"><span>Layers</span>
                <v-spacer></v-spacer>
                <v-btn @click="layersMenuActive = false" icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-subheader>
            </div>
            <v-list-item v-for="layer in layers" :key="layer.name">
              <v-list-item-content>
                <v-list-item-title v-text="layer.heading"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </div>
    <!--Map Legend-->
    <div v-if="clcPlusLayerActive && legendActive" style="position: absolute; bottom: 40px; right: 20px; z-index: 100;">
      <v-card
          outlined
          width="340"
          class="rounded-lg"
      >
        <v-card-title class="pb-0">Land Cover
          <v-spacer></v-spacer>
          <v-btn @click="legendActive = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-for="legendItem in clcPlusLegendData" :key="legendItem.hex" class="d-flex flex-row align-center mb-1">
            <div class="rounded" style="width:15px; height:15px" :style="'background-color:'+legendItem.hex"></div>
            <div class="ml-2">{{ legendItem.label }}</div>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <!--Map Legend Button-->
    <div v-if="clcPlusLayerActive" style="position: absolute; bottom: 40px; right: 20px; z-index: 90;">
      <v-btn v-if="!legendActive" @click="legendActive = true"
             color="primary" dark rounded fab small>
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import View from 'ol/View';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import "ol/ol.css";
import {BingMaps, TileWMS, XYZ} from "ol/source";
import {useGlobalStore} from "@/stores/global";
import {mapState} from "pinia";
import {Tile} from "ol/layer";

export default {
  name: "MapService",
  data() {
    return {
      map: null,
      layers: [
        {
          name: 'clcPlus',
          heading: 'Copernicus Land Monitoring Service - CLC+ Backbone Raster',
          url: 'https://tileserver.showroom.geoville.com/CLCplus_RASTER_2018/{z}/{x}/{y}.png',
          main: false,
        },
        {
          name: 'wildfire',
          heading: 'Wildfires',
          url: 'https://maps.effis.emergency.copernicus.eu/gwis',
          params: {'LAYERS': 'ecmwf.fwi', 'TILED': true, 'VERSION': '1.1.1', 'TIME': '2024-11-15'},
          main: true
        }
      ],
      baseLayers: [{
        name: 'osm',
        heading: 'OpenStreetMap',
        main: true,
        img: require('@/assets/baselayer_preview/bm_osm.png'),
      }, {
        name: 'bing',
        heading: 'Bing Maps',
        main: false,
        img: require('@/assets/baselayer_preview/bm_sat.png'),
      }],
      selectedBaseLayer: 0,
      selectedLayers: [1],
      baseLayerMenuActive: false,
      layersMenuActive: false,
      clcPlusLayerActive: false,
      clcPlusLegendData: [
        {"hex": "#FF0000", "label": "Sealed"},
        {"hex": "#228B22", "label": "Woody - needle leaved trees"},
        {"hex": "#80FF00", "label": "Woody - Broadleaved deciduous trees"},
        {"hex": "#00FF08", "label": "Woody - Broadleaved evergreen trees"},
        {"hex": "#804000", "label": "Low-growing woody plants (bushes, shrubs)"},
        {"hex": "#CCF24D", "label": "Permanent herbaceous"},
        {"hex": "#FFFF80", "label": "Periodically herbaceous"},
        {"hex": "#FF80FF", "label": "Lichens and mosses"},
        {"hex": "#BFBFBF", "label": "Non-and-sparsely-vegetated"},
        {"hex": "#0080FF", "label": "Water"},
        {"hex": "#00FFFF", "label": "Snow and ice"}
      ],
      legendActive: true,
    };
  },
  mounted() {
    this.initMap();
    this.legendActive = window.innerWidth > 672;
  },
  computed: {
    ...mapState(useGlobalStore, ['navDrawerExpanded'])
  },
  watch: {
    navDrawerExpanded() {
      if (!useGlobalStore().navDrawerExpanded && window.innerWidth < 672) {
        this.legendActive = false;
      }
    },
    selectedBaseLayer: function () {
      this.baseLayerMenuActive = false;
      this.changeBaseLayer(this.baseLayers[this.selectedBaseLayer].name);
    },
    selectedLayers: function () {
      this.setLayersVisibility(this.selectedLayers);
    }
  },
  methods: {
    initMap() {
      this.map = new Map({
        target: 'map',
        view: new View({
          zoom: 4,
          center: [1267020.1808550828, 7646148.813422751],
          constrainResolution: true
        }),
      });
      this.initBaseLayers();
      this.initLayers();

      this.map.on('click', () => {
        this.baseLayerMenuActive = false;
      });
    },
    initBaseLayers() {
      this.baseLayers.forEach((layer) => {
        let newLayer;
        if (layer.name === 'osm') {
          newLayer = new TileLayer({
            source: new OSM(),
            className: layer.name,
            visible: layer.main,
            zIndex: 10,
          });
        } else if (layer.name === 'bing') {
          newLayer = new TileLayer({
            source: new BingMaps({
              key: process.env.VUE_APP_BING_KEY,
              imagerySet: 'Aerial',
            }),
            className: layer.name,
            visible: layer.main,
            zIndex: 10,
          });
        }
        this.map.addLayer(newLayer);
      });
    }
    ,
    initLayers() {
      this.layers.forEach((layer) => {
        if (layer.name === 'clcPlus') {
          this.clcPlusLayerActive = layer.main;
          this.map.addLayer(new TileLayer({
            source: new XYZ({
              url: layer.url,
              minZoom: 1,
              maxZoom: 13,
              attributions: "CLC+ backbone: European Unions Copernicus Land Monitoring Service information",
            }),
            className: layer.name,
            visible: layer.main,
            zIndex: 11,
          }));
        } else if (layer.name === 'wildfire') {
          this.map.addLayer(new Tile({
            opacity: 0.7,
            source: new TileWMS({
              url: layer.url,
              params: layer.params,
              attributions: "EFFIS - Copernicus [Cookies] [Legal Notice] | [Ver. 2.7.32]"
            }),
            className: layer.name,
            visible: layer.main,
            zIndex: 11,
          }));

        }
      });
    },
    changeBaseLayer(layerName) {
      this.map.getLayers().forEach(layer => {
        if (layer.getZIndex() === 10) layer.setVisible(false);
        if (layer.getClassName() === layerName) layer.setVisible(true);
      });
    },
    setLayersVisibility(selectedLayers) {
      let selectedLayersNames = selectedLayers.map(layerIndex => this.layers[layerIndex].name);
      this.map.getLayers().forEach(layer => {
        if (layer.getZIndex() > 10) {
          let isVisible = selectedLayersNames.includes(layer.getClassName());
          layer.setVisible(isVisible);
          if (layer.getClassName() === 'clcPlus') {
            this.clcPlusLayerActive = isVisible;
          }
        }
      });
    }
  }
};
</script>
<style scoped>
#map {
  width: auto;
  height: calc(100vh - 48px);
  background-color: #242423;
  /* important: keeps the design edge: */
  overflow: hidden;
}

::v-deep .ol-zoom {
  visibility: hidden;
}
</style>