<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title class="text-h5">
          conneXstream Service Utilization Platform
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <div class="text-center text-h6">Meet our partners</div>
          <div class="text-center text-body-1">The conneXstream Service Utilization Platform was developed in collaboration with top partners in the
            earth observation
            industry.
          </div>
          <v-carousel cycle interval="3000" height="150" hide-delimiter-background show-arrows-on-hover>
            <v-carousel-item v-for="(partner, i) in partners" :key="i" transition="slide-x-reverse-transition">
              <v-container fill-height fluid>
                <v-row align="center" justify="center">
                  <a :href="partner.url" target="_blank">
                    <img :src="partner.image" style="filter: invert(100%); width: auto; height: 50px;"
                         :alt="partner.name"/>
                  </a>
                </v-row>
              </v-container>
            </v-carousel-item>
          </v-carousel>
          <div class="text-center">PROVIDED AND DEVELOPED BY</div>
          <v-container fill-heigth fluid>
            <v-row justify="center" align="center">
              <div>
                <a :href="consortium.geoville.url" target="_blank">
                  <img :src="consortium.geoville.image" :alt="consortium.geoville.name" style="width: 100px; height:auto"/>
                </a>
              </div>
              <div style="margin-top:10px; margin-left: 10px; margin-right: 10px">
                <a :href="consortium.smartConnect.url" target="_blank">
                  <img :src="consortium.smartConnect.image" :alt="consortium.smartConnect.name" style="width: auto; height:50px"/>
                </a>
              </div>
              <div>
                <a :href="consortium.esa.url" target="_blank">
                  <img :src="consortium.esa.image" :alt="consortium.esa.name" style="width: 100px; height:auto"/>
                </a>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {eventBus} from "@/main.js";

export default {
  name: "AboutDialog.vue",
  components: {},
  data() {
    return {
      dialog: false,
      consortium: {
        geoville: {
          name: 'GeoVille',
          url: "https://www.geoville.com/",
          image: require('@/assets/partners/geoville.png'),
        },
        smartConnect: {
          name: 'Smart Connect',
          url: "https://connexstream.com/",
          image: require('@/assets/partners/smart-connect.png'),
        },
        esa: {
          name: 'ESA',
          url: "https://www.esa.int/",
          image: require('@/assets/partners/esa.png'),
        },
      },
      partners: [
        {
          name: 'Earth Streamer',
          url: 'https://earthreceiver-u10615.vm.elestio.app/',
          image: require('@/assets/partners/earth-streamer.png')
        },
        {
          name: 'Epic Blue',
          url: 'https://www.epic.blue/',
          image: require('@/assets/partners/epicblue.png')
        },
        {
          name: 'Gemsotec',
          url: 'https://www.gemsotec.com/',
          image: require('@/assets/partners/gemsotec.png')
        },
        {
          name: 'INOV',
          url: 'https://www.inov.pt/en/',
          image: require('@/assets/partners/inov.png')
        },
        {
          name: 'Klepsydra',
          url: 'https://klepsydra.com/',
          image: require('@/assets/partners/klepsydra.png')
        },
        {
          name: 'Kuva Space',
          url: 'https://kuvaspace.com/',
          image: require('@/assets/partners/kuva-space.png')
        },
        {
          name: 'Nazka',
          url: 'https://www.nazka.be/en/',
          image: require('@/assets/partners/nazka.png')
        },
        {
          name: 'neXat',
          url: 'https://www.nexat.be/',
          image: require('@/assets/partners/nexat.png')
        },
        {
          name: 'SISTEMA',
          url: 'https://www.sistema.at/',
          image: require('@/assets/partners/sistema.png')
        },
        {
          name: 'VITO',
          url: 'https://remotesensing.vito.be/',
          image: require('@/assets/partners/vito.png')
        },
      ],
    };
  },
  mounted() {
    eventBus.$on('show-about-dialog', (data) => {
      this.dialog = data;
    });
  },
  methods: {}
};
</script>

<style>
.logos {
  padding: 5px;
  border-radius: 5px;
  text-align: -webkit-center;
}
</style>