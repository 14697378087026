<template>
  <div>
    <v-app-bar app clipped-left dark dense color="black" elevation="0">
      <img :src="require('@/assets/connexstream_circle_white.svg')" class="icon mr-2" alt="connexstream circle"
           height="32px">
      <img :src="require('@/assets/connexstream_lettering_white.svg')" class="icon" alt="connexstream lettering"
           height="20px">
      <v-spacer></v-spacer>
      <v-btn id="backSmallScreen" :href="connexStreamUrl" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn id="backBigScreen" :href="connexStreamUrl" class="rounded-lg" text>Back to Order Process</v-btn>
      <v-btn icon @click="showAboutDialog()">
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
      <v-menu offset-y style="z-index: 101">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-account-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in items" :key="item.name" style="padding: 0">
            <v-list-item-action style="margin: 0">
              <v-btn @click="item.action" text>{{ item.name }}</v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import {useAuthStore} from '@/stores/auth';
import {eventBus} from "@/main.js";

export default {
  name: "AppBar",
  components: {},
  data() {
    return {
      items: [
        {name: "Profile", action: this.goToProfile},
        {name: "Logout", action: this.logout},
      ],
      connexStreamUrl: process.env.VUE_APP_CONNEXSTREAM_URL
    };
  },
  methods: {
    goToProfile() {
      this.$router.push({path: "/profile"});
    },
    logout() {
      useAuthStore().logout();
    },
    showAboutDialog() {
      eventBus.$emit("show-about-dialog", true);
    }
  }
};
</script>

<style>

#backSmallScreen {
  visibility: hidden;
}

@media only screen and (max-width: 768px) {
  #backSmallScreen {
    visibility: visible;
  }

  #backBigScreen {
    display: none;
  }
}

.icon:hover {
  cursor: pointer;
}
</style>