<template>
    <v-card>
        <v-card-title class="text-h6 grey lighten-2">
            <v-icon large style="margin-right:15px">mdi-account-circle-outline</v-icon>User information
        </v-card-title>
        <v-card-text style="color: black; margin-top: 15px">
            <v-row v-for="(item, key) in user" :key="key">
                <v-col cols="2">
                    <p style="font-size:medium">{{ key === 'used_gb' ? 'Used Contingent' : getNameUpperCase(key) }}:</p>
                </v-col>
                <v-col>
                    <p style="font-size:medium">{{key === 'used_gb' ? $helpers.formatGigaBytes(item) : item }} </p>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
  
<script>
import { useUserStore } from '@/stores/user'

export default {
    name: "ProfileCard",
    data() {
        return {
        };
    },
    components: {
    },
    computed: {
        user() {
            return useUserStore().profile;
        }
    },
    methods: {
        getNameUpperCase(name) {
            let items = name.split('_');
            let itemsUpper = items.map(a => a.charAt(0).toUpperCase() + a.substr(1))
            return itemsUpper.join(' ');
        },
    },

};
</script>
  
<style scoped>

</style>