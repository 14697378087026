import { defineStore } from "pinia";
import axios_services from "./../axios-services";

export const useAllFilesStore = defineStore("allFiles", {
  state: () => {
    return {
      files: [],
      filesLoading: false,
    };
  },
  getters: {},
  actions: {
    fetchAllFiles() {
      this.filesLoading = true;
      axios_services
        .get("files")
        .then((response) => {
          for (let file in response.data["files"]) {
            this.files.push(response.data["files"][file]);
            this.files[file]['timestamp'] = new Date(this.files[file]['timestamp'] + 'Z').toLocaleString("sv-SE").replace(" ", " ").split(".")[0];
          }
          this.filesLoading = false;
        })
        .catch((error) => {
          console.log("error: cannot fetch data", error);
          this.filesLoading = false;
        });
    },
  },
});
