import axios from "axios";
import { useAuthStore } from "@/stores/auth";

const axios_services = axios.create({
  baseURL: process.env.VUE_APP_CONNEXSTREAM_BACKEND_URL,
});

axios_services.interceptors.request.use(
  function (config) {
    let key = "Bearer " + useAuthStore().accessToken;
    config.headers.Authorization = key;
    //config.headers["Content-Type"] = 'multipart/form-data';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios_services;
