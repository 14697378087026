import { defineStore } from 'pinia'
import Vue from "vue";

export const useAuthStore = defineStore('auth', {
  state: () => {
    return { accessToken: null,
     }
  },
  actions: {
    logout() {
      this.accessToken = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      Vue.$keycloak.logout({ redirectUri: "https://dev.er.connexstream.com/" });
    }
  },
})