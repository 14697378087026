<script>
import LiveStreamService from "@/views/livestream/LiveStreamService.vue";
import RecentLiveStreamsList from "@/views/livestream/RecentLiveStreamsList.vue";
import StartOrSelectLiveStream from "@/views/livestream/StartOrSelectLiveStream.vue";
import {useLiveStreamStore} from "@/stores/livestream";

export default {
  name: "LiveStreamIndex",
  methods: {
    useLiveStreamStore,
    resetLivestream() {
      useLiveStreamStore().isLivestreamVideoActive = false;
      useLiveStreamStore().activeVideo = {};
      useLiveStreamStore().videoIndex = -1;
      useLiveStreamStore().videoDate = '';
    }
  },
  components: {StartOrSelectLiveStream, RecentLiveStreamsList, LiveStreamService},
};
</script>

<template>
  <v-card style="height: fit-content" class="rounded-lg">
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col>
            <div class="text-h5">Livestream</div>
          </v-col>
        </v-row>
        <v-row v-if="!useLiveStreamStore().isLivestreamVideoActive">
          <v-col xs="12" sm="12" md="12" lg="6">
            <StartOrSelectLiveStream/>
          </v-col>
          <v-col xs="12" sm="12" md="12" lg="6">
            <v-btn style="width: 100%" v-if="useLiveStreamStore().isLivestreamVideoActive"
                   @click="resetLivestream">Back to Livestream Configuration
            </v-btn>
            <RecentLiveStreamsList/>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col xs="12" sm="12" md="12" lg="7">
            <LiveStreamService/>
          </v-col>
          <v-col xs="12" sm="12" md="12" lg="5">
            <v-btn style="width: 100%" v-if="useLiveStreamStore().isLivestreamVideoActive"
                   @click="resetLivestream">Back to Livestream Configuration
            </v-btn>
            <RecentLiveStreamsList/>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>