<template>
  <v-app>
    <AddFileDialog/>
    <NavigationDrawer/>
    <AppBar/>
    <NotificationSnackBar/>
    <CommentDialog/>
    <AboutDialog/>
    <DownloadDialog/>

    <v-main>
      <v-container fluid
                   :style="router().currentRoute.name === 'map' ? 'margin: 0 !important; padding: 0 !important' : ''">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationDrawer from '@/components/NavigationDrawer/NavigationDrawer.vue';
import AppBar from '@/components/AppBar.vue';
import AddFileDialog from '@/components/AddFileDialog.vue';
import NotificationSnackBar from '@/components/NotificationSnackBar.vue';
import {useUsersStore} from '@/stores/users';
import {useGroupsStore} from '@/stores/groups';
import CommentDialog from './components/CommentDialog.vue';
import AboutDialog from '@/components/AboutDialog.vue';
import DownloadDialog from './components/DownloadDialog.vue';
import router from "@/router/routes";

export default {
  name: 'App',
  methods: {
    router() {
      return router;
    }
  },
  components: {
    NavigationDrawer,
    AppBar,
    AddFileDialog,
    NotificationSnackBar,
    CommentDialog,
    AboutDialog,
    DownloadDialog,
  },
  data: () => ({}),
  mounted() {
    useUsersStore().fetchUsers();
    useGroupsStore().fetchGroups();
  }
};
</script>

<style scoped>
#app {
  background: #F4F5FA;
}
</style>
