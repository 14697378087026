<template>
  <div>
    <v-card elevation="0" class="rounded-lg">
      <v-data-table :headers="headers" :items="myFiles" :items-per-page="10" :loading="myFilesLoading == true"
                    loading-text="Data loading..." class="elevation-1">

        <template v-slot:[`header.download_time`]="{ header }">
          {{ header.text }}
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon @click="updateDownloadTime()" v-on="on">
                mdi-update
              </v-icon>
            </template>
            <span>Update download time</span>
          </v-tooltip>
        </template>

        <template v-slot:top>
          <v-toolbar flat class="rounded-lg">
            <v-toolbar-title>My Files
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

          </v-toolbar>
        </template>

        <!-- timestamp -->
        <template v-slot:[`item.timestamp`]="{ item }">
          {{ item.timestamp.slice(0, 19) }}
        </template>

        <!-- size -->
        <template v-slot:[`item.file_size`]="{ item }">
          {{ $helpers.formatBytes(item.file_size) }}
        </template>

        <!-- comments -->
        <template v-slot:[`item.comments_count`]="{ item }">
          <v-btn icon @click="comment(item)">
            <v-badge color="blue" :content="item.comment_count.toString()" overlap bordered>
              <v-icon class="">
                mdi-message-text-outline
              </v-icon>
            </v-badge>
          </v-btn>
        </template>

        <!-- shared -->
        <template v-slot:[`item.allowed_user`]="{ item }">
          <div v-if="item.allowed_users.length > 0 || item.allowed_groups.length > 0">
            <v-chip class="chip-shared" small @click="showSharedDetails(item)">
              <v-icon class="mr-2" small>mdi-share-variant</v-icon>
              Shared
            </v-chip>
          </div>
          <div v-else>
          </div>
        </template>

        <!-- shared -->
        <template v-slot:[`item.download_time`]="{ item }">
          <div v-if="!downloadTimeReady">
            <v-progress-circular
                indeterminate
                color="primary"
                :size="20"
            ></v-progress-circular>
          </div>
          <div v-else>
            {{ getDownloadTimeStr(item.download_time) }}
          </div>
        </template>

        <!-- actions -->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-row">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon small @click="shareFile(item)" v-on="on">
                  mdi-share-variant {{ item }}
                </v-icon>
              </template>
              <span>Share file</span>
            </v-tooltip>

            <v-tooltip top v-if="!isDownloading || !(selectedItem == item.file_id)" :key="isDownloading">
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-3 ml-3" @click="downloadFile(item)" v-on="on">
                  mdi-download {{ item }}
                </v-icon>
              </template>
              <span>Download file</span>
            </v-tooltip>

            <v-tooltip top v-if="isDownloading && (selectedItem == item.file_id)" :key="isDownloading">
              <template v-slot:activator="{ on }">
                <v-progress-circular class="mr-3 ml-3" :size="20" indeterminate color="primary"
                                     v-on="on"></v-progress-circular>
              </template>
              <span>Downloading file</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon small @click="deleteFile(item)" v-on="on">
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete file</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {eventBus} from "@/main.js";
import {useMyFilesStore} from '@/stores/myfiles';
import fileDownloadMixin from '@/mixins/file_download_mixin.js';
import {useDownloadingStore} from '@/stores/downloading';


export default {
  name: "MyFilesTable",
  mixins: [fileDownloadMixin],
  data() {
    return {
      headers: [
        {text: 'File name', value: 'file_name', align: 'start', sortable: true},
        {text: 'Uploaded at', value: 'timestamp', sortable: true, name: 'uploaded_at'},
        {text: 'Size', value: 'file_size', sortable: true},
        {text: 'Download time', value: 'download_time', sortable: false},
        {text: 'Shared', value: 'allowed_user', sortable: false},
        {text: 'Comments', value: 'comments_count', sortable: false},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      selectedItem: -1,
    };
  },
  components: {},
  computed: {
    myFiles() {
      return useMyFilesStore().files;
    },
    myFilesLoading() {
      return useMyFilesStore().filesLoading;
    },
    downloadTimeReady() {
      return useMyFilesStore().downloadRateReady;
    },
    isDownloading() {
      return useDownloadingStore().downloading;
    }
  },
  mounted() {
    useDownloadingStore().downloading = false;
  },
  methods: {
    showSharedDetails(item) {
      eventBus.$emit("show-shared-view-dialog", item);
    },
    shareFile(item) {
      eventBus.$emit("show-share-file-dialog", item);
    },
    downloadFile(item) {
      console.log(item.file_name);
      console.log(item.file_path);
      if (!useDownloadingStore().downloading) {
        this.selectedItem = item.file_id;
        this.download(item);  // called from mixin object
      } else {
        eventBus.$emit("show-snackbar", {
          type: "error",
          title: "Error",
          msg: "Wait for the other file to finish downloading."
        });
      }
    },
    deleteFile(item) {
      eventBus.$emit("show-delete-file-dialog", item);
    },
    comment(item) {
      eventBus.$emit("toggle-comment-dialog", item);
    },
    updateDownloadTime() {
      useMyFilesStore().updateDownloadTime();
    },
    getDownloadTimeStr(time) {
      if (time < 1.0) {
        return "<1 sec";
      } else {
        let minutes = Math.floor(time / 60);
        let remainingSeconds = time % 60;

        if (minutes === 0) {
          return Math.ceil(remainingSeconds) + " sec";
        } else {

          var formattedTime = minutes + " min ";
          formattedTime += Math.ceil(remainingSeconds) + " sec";

          return formattedTime;
        }
      }
    }
  }

};
</script>

<style scoped>
.chip-shared:hover {
  cursor: pointer;
}
</style>