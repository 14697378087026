import { eventBus } from "@/main.js";
import axios_services from "@/axios-services";
import axios from "axios";
import Go from "../wasm/wasm_exec";
import { useDownloadingStore } from "@/stores/downloading";

// Define mixin object
const fileDownloadMixin = {
  data() {
    return {};
  },
  methods: {
    createAndTriggerDownloadLink: function (url, fileName) {
      const link = document.createElement("a");
      link.href =
        typeof url === "string" ? url : window.URL.createObjectURL(url);
      link.download = fileName;
      link.click();
      link.remove();
    },
    download: async function (item) {
      axios_services
        .get(`files/download/${item.file_id}`)
        .then(async (response) => {
          let downloadUrl = response.data.download_url;
          useDownloadingStore().downloadSize = response.data.file_size;
          let updatedItem = {};
          if (!item.compressed && downloadUrl.includes(".zst")) {
            await axios_services
              .get(`files/${item.file_id}`)
              .then((r) => (updatedItem = r.data));
          }

          let isCompressed =
            (item.compressed || updatedItem.compressed) &&
            downloadUrl.includes(".zst");

          // check if file is compressed
          if (!isCompressed) {
            this.createAndTriggerDownloadLink(downloadUrl, item.file_name);
          } else {
            useDownloadingStore().downloading = true;
            // download actual file
            axios
              .get(downloadUrl, {
                responseType: "blob",
                onDownloadProgress: (progressEvent) =>
                  (useDownloadingStore().downloadProgress =
                    progressEvent.loaded),
              })
              .then(async (s3Response) => {
                const blob = await s3Response.data;

                let fileReader = new FileReader();
                fileReader.onload = async function (event) {
                  let bytes = new Uint8Array(event.target.result);

                  let result;
                  //decompress with wasm
                  const go = new Go();
                  await WebAssembly.instantiateStreaming(
                    fetch("decompressor.wasm"),
                    go.importObject
                  ).then((res) => {
                    go.run(res.instance);
                    result = new Blob([Decompress(bytes)]); // eslint-disable-line
                  });
                  this.createAndTriggerDownloadLink(result, item.file_name);
                  global.Decompress = undefined;
                  global.Go = undefined;
                  useDownloadingStore().downloading = false;
                }.bind(this);
                fileReader.readAsArrayBuffer(blob);
              })
              .catch((error) => {
                eventBus.$emit("show-snackbar", {
                  type: "error",
                  title: "Error",
                  msg: "File could not be downloaded.",
                });
                console.log("error: cannot download data", error);
                useDownloadingStore().downloading = false;
              });
          }
        })
        .catch((error) => {
          eventBus.$emit("show-snackbar", {
            type: "error",
            title: "Error",
            msg: "File could not be downloaded.",
          });
          console.log("error: cannot download data", error);
          useDownloadingStore().downloading = false;
        });
    },
  },
};

export default fileDownloadMixin;
