<script>
import {useLiveStreamStore} from "@/stores/livestream";

export default {
  name: "StartOrSelectLiveStream",
  methods: {
    configureLiveStream() {
      console.log("Todo");
    },
    playLiveStream() {
      useLiveStreamStore().activeVideo = {
        // eslint-disable-next-line no-undef
        videoTitle: 'Livestream',
        videoDescription: '',
        videoDetails: {
          bitrate: '2000Kbps',
          resolution: '480p',
          fps: '30FPS',
        },
        videoLocation: [11.435620, 47.262560],
        videoDuration: -1,
        videoUrl: 'https://dronenstream.geoville.com/live/livestream.flv'
      };
      useLiveStreamStore().videoDate = '';
      useLiveStreamStore().videoIndex = -1;
      useLiveStreamStore().isLivestreamVideoActive = true;
    },
  },
  data() {
    return {
      isStartLiveStreamProcessActive: false,
    };
  }
};
</script>

<template>
  <div v-if="!isStartLiveStreamProcessActive" class="d-flex flex-column mt-10">
    <v-btn @click="isStartLiveStreamProcessActive = true" class="rounded-lg mb-4" style="width: 100%" color="primary">
      Start new livestream
    </v-btn>
    <v-btn @click="playLiveStream()" class="rounded-lg" style="width: 100%">Watch Livestream
    </v-btn>
  </div>
  <div v-else>
    <div>
      <div class="d-flex flex-row">
        <v-select label="Resolution" :items="[480, 720, 1080]" class="mr-1"></v-select>
        <v-select label="Bitrate" :items="[200, 400, 800, 1000]"></v-select>
      </div>
      <div>
        <v-select style="width: 50%" label="FPS" :items="[24, 30, 60]"></v-select>
      </div>
    </div>
    <div class="d-flex flex-row" style="width: 100%;">
      <v-btn style="width: 50%" class="mr-1" @click="isStartLiveStreamProcessActive = false">Cancel</v-btn>
      <v-btn style="width: 50%" color="primary" @click="playLiveStream()">Start</v-btn>
    </div>
  </div>
</template>

<style scoped>

</style>