import {defineStore} from "pinia";

export const useGlobalStore = defineStore("globalStore", {
    state: () => {
        return {
            navDrawerExpanded: false,
        };
    },
    actions: {
        toggleNavDrawer() {
            this.navDrawerExpanded = !this.navDrawerExpanded;
        }
    }
});