<template>
  <div>
    <v-navigation-drawer v-if="!useGlobalStore().navDrawerExpanded" app left permanent
                         :style="{ top: $vuetify.application.top + 'px', zIndex: 6 }"
                         color="#F4F5FA">
      <div class="text-right">
        <v-btn @click="useGlobalStore().toggleNavDrawer()" icon large>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
      <NavigationDrawerContent/>
    </v-navigation-drawer>
    <v-navigation-drawer v-else mini-variant app left permanent
                         :style="{ top: $vuetify.application.top + 'px', zIndex: 6 }"
                         color="#F4F5FA">
      <div class="text-center">
        <v-btn @click="useGlobalStore().toggleNavDrawer()" icon large>
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <NavigationDrawerContent/>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {useUserStore} from '@/stores/user';
import NavigationDrawerContent from "@/components/NavigationDrawer/NavigationDrawerContent.vue";
import {useGlobalStore} from "@/stores/global";

export default {
  name: "NavigationDrawer",
  components: {NavigationDrawerContent},
  data() {
    return {
      items: [
        {title: "My Files", icon: "mdi-file", path: "/", requiredAdmin: false},
        {title: "Shared Files", icon: "mdi-share-variant", path: "/shared-with-me", requiredAdmin: false},
        {title: "All Files", icon: "mdi-file-multiple", path: "/all-files", requiredAdmin: true},
        {title: "Recent", icon: "mdi-history", path: "/recent", requiredAdmin: false},
        {title: "Users", icon: "mdi-account", path: "/users", requiredAdmin: true},
        {title: "Groups", icon: "mdi-account-multiple", path: "/groups", requiredAdmin: true},
      ],
      itemsBottom: [
        {title: "Livestreams", icon: 'mdi-quadcopter', path: "/livestream"},
        {title: "Map", icon: 'mdi-map', path: "/map"},
      ]
    };
  },
  computed: {
    isAdmin() {
      return useUserStore().isAdmin;
    },
    user() {
      return useUserStore().user;
    }
  },
  methods: {
    useGlobalStore,
  },
  mounted() {
    useGlobalStore().navDrawerExpanded = window.innerWidth < 768;
  }
};
</script>

<style scoped>

</style>